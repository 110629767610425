function togglePasswordVisibility(inputId, iconId) {
    const passwordInput = document.getElementById(inputId);
    const toggleIcon = document.getElementById(iconId);
    
    if (passwordInput.type === 'password') {
      passwordInput.type = 'text';
      toggleIcon.classList.remove('bi-eye-fill');
      toggleIcon.classList.add('bi-eye-slash-fill');
    } else {
      passwordInput.type = 'password';
      toggleIcon.classList.remove('bi-eye-slash-fill');
      toggleIcon.classList.add('bi-eye-fill');
    }
  }
  
  // 関数をグローバルに登録
  window.togglePasswordVisibility = togglePasswordVisibility;